import React from "react";
import ReactDOM from "react-dom";
import axios from "axios";

import {EnvProvider} from "./context/EnvContext";
import {LanguageProvider} from "./context/LanguageContext";
import {PermissionNotificationProvider} from "./context/PermissionNotificationContext";
import authConfig from "./auth_config.json";
import config from "./config.json";
import {Auth0Provider} from "./react-auth0-spa";
import Router from "./Router";
import * as serviceWorker from "./serviceWorker";

import "./index.css";
import "semantic-ui-css/semantic.min.css";

//axios.defaults.withCredentials = true;
axios.defaults.xsrfHeaderName = "X-CSRFTOKEN";
axios.defaults.xsrfCookieName = "csrftoken";
let env = "dev";

switch (window.location.hostname) {
  case "console.voiceable.co":
  case "voice-able.com":
    env = "prod";
    break;
  case "console.voice-able.com":
    env = "dev";
    break;
  case "localhost":
    env = "local";
    break;
  default:
    env = "dev";
    break;
}
axios.defaults.baseURL = config["api"][env];
// A function that routes the user to the right place
// after login
const onRedirectCallback = (appState) => {
  window.history.replaceState(
    {},
    document.title,
    appState && appState.targetUrl
      ? appState.targetUrl
      : window.location.pathname
  );
};
console.log(env);
ReactDOM.render(
  <Auth0Provider
    domain={authConfig.domain}
    client_id={authConfig.clientId}
    audience={authConfig.audience}
    redirect_uri={window.location.origin}
    onRedirectCallback={onRedirectCallback}
  >
    <LanguageProvider
      value={{languageCode: localStorage.getItem("languageCode") || "en"}}
    >
      <EnvProvider value={env}>
        <PermissionNotificationProvider>
          <Router/>
        </PermissionNotificationProvider>
      </EnvProvider>
    </LanguageProvider>
  </Auth0Provider>,
  document.getElementById("root")
);
serviceWorker.register();
